import React from "react"

import Layout from "../components/layout"
import { FooterAlternate1 } from "../components/footer"
import SEO from "../components/seo"

const IndexPage = () => (
  <Layout>
    <SEO title="Privacy Policy" />

    <article className="article">
      <header className="header header--basic article__header">
        <div className="header__content">
          <div className="container">
            <div className="row">
              <div className="header__inner col-md-9 col-lg-7 col-lg-offset-1">
                <h1 className="header__title">Privacy Policy</h1>
              </div>
            </div>
          </div>
        </div>
      </header>
      <div className="container" id="article-content">
        <div className="row">
          <div className="article__body col">
            <p>
              Sales Hub Pty Ltd is committed to providing quality products &
              services to you and this policy outlines our ongoing obligations
              to you in respect of how we manage your Personal Information.
            </p>
            <p>
              We have adopted the Australian Privacy Principles (APPs) contained
              in the Privacy Act 1988 (Cth) (the Privacy Act). The APPs govern
              the way in which we collect, use, disclose, store, secure and
              dispose of your Personal Information. A copy of the Australian
              Privacy Principles may be obtained from the website of The Office
              of the Australian Information Commissioner at
            </p>
            <h3>
              <strong>
                What is Personal Information and why do we collect it?
              </strong>
            </h3>
            <p>
              Personal Information is information or an opinion that identifies
              an individual. Examples of Personal Information we collect
              include: names, addresses, email addresses, phone and facsimile
              numbers. This Personal Information is obtained in many ways
              including meetings, interviews, correspondence, by telephone and
              facsimile, by email, via our website, from your website, from
              media and publications, from other publicly available sources,
              from cookies, and from third parties.
            </p>
            <p>
              We don’t guarantee website links or policy of authorised third
              parties. We collect your Personal Information for the primary
              purpose of providing our products & services to you, providing
              information to our clients and marketing. We may also use your
              Personal Information for secondary purposes closely related to the
              primary purpose, in circumstances where you would reasonably
              expect such use or disclosure. You may unsubscribe from our
              mailing/marketing lists at any time by contacting us in writing.
            </p>
            <p>
              When we collect Personal Information we will, where appropriate
              and where possible, explain to you why we are collecting the
              information and how we plan to use it.
            </p>

            <h3>
              <strong>Sensitive Information</strong>
            </h3>
            <p>
              Sensitive information is defined in the Privacy Act to include
              information or opinion about such things as an individual’s racial
              or ethnic origin, political opinions, membership of a political
              association, religious or philosophical beliefs, membership of a
              trade union or other professional body, criminal record or health
              information.
            </p>
            <p>Sensitive information will be used by us only:</p>
            <p>
              For the primary purpose for which it was obtained
              <br /> For a secondary purpose that is directly related to the
              primary purpose
              <br /> With your consent; or where required or authorised by law.
            </p>
            <h3>
              <strong>Third Parties</strong>
            </h3>
            <p>
              Where reasonable and practicable to do so, we will collect your
              Personal Information only from you. However, in some circumstances
              we may be provided with information by third parties. In such a
              case we will take reasonable steps to ensure that you are made
              aware of the information provided to us by the third party.
            </p>
            <h3>
              <strong>Disclosure of Personal Information</strong>
            </h3>
            <p>
              Your Personal Information may be disclosed in a number of
              circumstances including the following:
            </p>
            <p>
              Third parties only where you consent to the use or disclosure; and
              Where required or authorised by law.
            </p>
            <h3>
              <strong>Security of Personal Information</strong>
            </h3>
            <p>
              Your Personal Information is stored in a manner that reasonably
              protects it from misuse and loss and from unauthorized access,
              modification or disclosure. When your Personal Information is no
              longer needed for the purpose for which it was obtained, we will
              take reasonable steps to destroy or permanently de-identify your
              Personal Information. However, most of the Personal Information is
              or will be stored in client files which will be kept by us for a
              minimum of 7 years.
            </p>
            <h3>
              <strong>Access to your Personal Information</strong>
            </h3>
            <p>
              You may access the Personal Information we hold about you and to
              update and/or correct it, subject to certain exceptions. If you
              wish to access your Personal Information, please contact us in
              writing.
            </p>
            <p>
              Sales Hub Pty Ltd will not charge any fee for your access request,
              but may charge an administrative fee for providing a copy of your
              Personal Information. In order to protect your Personal
              Information we may require identification from you before
              releasing the requested information.
            </p>
            <h3>
              <strong>
                Maintaining the Quality of your Personal Information
              </strong>
            </h3>
            <p>
              It is an important to us that your Personal Information is up to
              date. We will take reasonable steps to make sure that your
              Personal Information is accurate, complete and up-to-date. If you
              find that the information we have is not up to date or is
              inaccurate, please advise us as soon as practicable so we can
              update our records and ensure we can continue to provide quality
              services to you.
            </p>
            <h3>
              <strong>Policy Updates</strong>
            </h3>
            <p>
              This Policy may change from time to time and is available on our
              website.
            </p>
            <h3>
              <strong>Privacy Policy Complaints and Enquiries</strong>
            </h3>
            <p>
              If you have any queries or complaints about our Privacy Policy
              please contact us at:
            </p>
            <p>
              Email:{" "}
              <a href="mailto:inquiries@salestribe.com">
                inquiries@salestribe.com
              </a>
            </p>
          </div>
        </div>
      </div>
    </article>

    <FooterAlternate1 />
  </Layout>
)

export default IndexPage
